import React from 'react'
import './about.css'
import ME from '../../assets/me-about.jpg'
import {FaAward} from 'react-icons/fa'
import {AiOutlineSafetyCertificate} from 'react-icons/ai'
import {VscFolderLibrary} from 'react-icons/vsc'

const About = () => {
  return (
    <section id='about'>
      <h5>Get To Know</h5>
      <h2>About Me</h2>

      <div className='container about__container'>
        <div className='about__me'>
          <div className='about__me-image'>
            <img src={ME} alt='About Image' />
          </div>
        </div>
      

      <div className='about__content'>
        <div className='about__cards'>
          <article className='about__card'>
            <FaAward className='about__icon'/>
            <h5>Experience</h5>
            <small>6+ Years Working</small>
          </article>

          <article className='about__card'>
            <AiOutlineSafetyCertificate className='about__icon'/>
            <h5>Certifications</h5>
            <small>3 Professional </small>
          </article>

          <article className='about__card'>
            <VscFolderLibrary className='about__icon'/>
            <h5>Projects</h5>
            <small>6+ Completed</small>
          </article>
        </div>

        <p>
          Strategic-minded MBA graduate with a specialization in Strategic Management, equipped with a solid foundation in business strategy, analysis, and leadership.
          Meticulous and knowledgeable Full Stack Consultant with education in engineering and management. 
          Dedicated leader with demonstrated ability to train, collaborate, manage, and motivate team members to achieve 
          organizational goals.
        </p>

        <a href="#contact" className='btn btn-primary'>Let's Talk</a>
      </div>
    </div>
    </section>
  )
}

export default About