import React from 'react'
import './services.css'
import {BiCheck} from 'react-icons/bi'

const Services = () => {
  return (
    <section id='services'>
      <h5>What I Offer</h5>
      <h2>Services</h2>

      <div className='container services__container'>
        <article className='services'>
          <div className='service__head'>
            <h3>UI/UX Design</h3>
          </div>

          <ul className='service__list'>
            <li>
              <BiCheck className='service__list-icon' />
              <p>User Modelling</p>
            </li>
            <li>
              <BiCheck className='service__list-icon' />
              <p>Visual Design</p>
            </li>
            <li>
              <BiCheck className='service__list-icon' />
              <p>Interaction Design</p>
            </li>
            <li>
              <BiCheck className='service__list-icon' />
              <p>User Research/Product Validation</p>
            </li>
            <li>
              <BiCheck className='service__list-icon' />
              <p>Functionality</p>
            </li>
            <li>
              <BiCheck className='service__list-icon' />
              <p>Design Sense/Taste</p>
            </li>            
          </ul>
         </article>
        <article className='services'>
          <div className='service__head'>
            <h3>Web Development</h3>
          </div>

          <ul className='service__list'>
            <li>
              <BiCheck className='service__list-icon' />
              <p>Website Maintenance</p>
            </li>
            <li>
              <BiCheck className='service__list-icon' />
              <p>Logo Design</p>
            </li>
            <li>
              <BiCheck className='service__list-icon' />
              <p>Email Marketing </p>
            </li>
            <li>
              <BiCheck className='service__list-icon' />
              <p>Full Stack Web Development</p>
            </li>
            <li>
              <BiCheck className='service__list-icon' />
              <p>QA and Testing</p>
            </li>            
          </ul>
         </article>
        <article className='services'>
          <div className='service__head'>
            <h3>Leadership</h3>
          </div>

          <ul className='service__list'>
            <li>
              <BiCheck className='service__list-icon' />
              <p>Quantitative KPIs </p>
            </li>
            <li>
              <BiCheck className='service__list-icon' />
              <p>Strategic Planning</p>
            </li>
            <li>
              <BiCheck className='service__list-icon' />
              <p>Forecasting</p>
            </li>
            <li>
              <BiCheck className='service__list-icon' />
              <p>Budget Management</p>
            </li>
            <li>
              <BiCheck className='service__list-icon' />
              <p>Cross-Functional Leadership</p>
            </li>
            <li>
              <BiCheck className='service__list-icon' />
              <p>Innovation</p>
            </li>            
          </ul>
         </article>
      </div>
    </section>
  )
}

export default Services